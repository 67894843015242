import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  label?: string
  value?: string
}

export const Discount = memo(function Discount({ label, value }: Props) {
  if (!value) {
    return null
  }

  return (
    <Container>
      {label ? <Label>{label}</Label> : null}

      <Value>{value}</Value>
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  padding: 1rem 1.875rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Label = styled.div`
  font-size: 1.125rem;
  line-height: 1.6875rem;
`

const Value = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.8125rem;
  margin-top: -0.25rem;
`
