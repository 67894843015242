import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  offers: OfferProps[]
  subtitle?: string
  title?: string
}

export const OffersList = memo(function OffersList({
  offers,
  subtitle,
  title,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [headRef, isHeadVisible] = useInView({ threshold: 0.5 })

  return (
    <Container>
      <Head className={isHeadVisible ? 'visible' : undefined} ref={headRef}>
        <Illustration
          alt=""
          src="/illustration-20.svg"
          width="228"
          height="268"
        />

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
      </Head>

      <Wrapper>
        {uniqBy(offers, 'title').map((item, index) => (
          <Offer key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 10.625rem 17.5vw 15.75rem;

  @media (max-width: 1199px) {
    padding: 7.5rem 1.5rem 5.625rem;
  }
`

const Head = styled.div`
  min-height: 29.8vh;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding-left: 1.25rem;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    width: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 0;
    bottom: 100%;
    left: 0;
    transition: 0.7s 0.4s cubic-bezier(0, 0.55, 0.45, 1);
  }
  &.visible {
    &:before {
      bottom: 0;
    }
  }

  @media (max-width: 1199px) {
    min-height: auto;
    padding-bottom: 7.5rem;
    padding-left: 0.9375rem;
  }
`

const Illustration = styled.img`
  width: auto;
  height: 29.8vh;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 1199px) {
    height: 5rem;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0.9375rem;
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.875rem;
  line-height: 2.25rem;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`

const Subtitle = styled.h2`
  max-width: 12.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-top: 0.375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`

const Wrapper = styled.div`
  margin-top: 6.25rem;

  @media (max-width: 1199px) {
    margin-top: 4rem;
  }
`
