import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Checklist } from './Checklist'
import { Discount } from './Discount'

export interface Props {
  cta?: ButtonProps
  checklist?: string[]
  checklistLabel?: string
  description?: string
  discountLabel?: string
  discountValue?: string
  image?: ImageProps
  title?: string
}

export const Offer = memo(function Offer({
  cta,
  checklist,
  checklistLabel,
  description,
  discountLabel,
  discountValue,
  image,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row stretch wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}

        {discountValue ? (
          <Discount label={discountLabel} value={discountValue} />
        ) : null}
      </LeftSide>

      <RightSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {checklist ? (
          <Checklist label={checklistLabel} list={checklist} />
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.75rem;
  &:first-of-type {
    margin-top: 0;
  }
`

const LeftSide = styled.div`
  width: 38.5%;
  height: 44.1vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    margin-bottom: 1.875rem;
    padding-bottom: 100%;
  }
`

const RightSide = styled(FlexBox)`
  width: calc(61.5% - 1.5625rem);
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin-left: 1.5625rem;
  padding-left: 1.25rem;

  @media (max-width: 1199px) {
    width: 100%;
    margin-left: 0;
    padding-left: 0.9375rem;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.125rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    line-height: 1.5625rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  margin-top: 1.25rem;

  @media (max-width: 1199px) {
    margin-top: 0.3125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.25rem;
`
