import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  label?: string
  list?: string[]
}

export const Checklist = memo(function Checklist({ label, list }: Props) {
  if (!list) {
    return null
  }

  if (list.length < 1) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        {list.map((item: any, index) => (
          <Item key={index}>{item.label}</Item>
        ))}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 1.25rem;
`

const Item = styled.div`
  margin-top: 0.625rem;
  padding-left: 2.875rem;
  position: relative;
  &:before {
    content: '';
    width: 1.875rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 1.0625rem;
    left: 0;
  }
`
